import React, { useState,useRef, useEffect } from 'react';
import { ReactMic } from 'react-mic';
import axios from 'axios';
import './App.css';
import logo from '/home/ubuntu/Conversational_Speech_Recognition_AI-/react-app/src/logo.png'
import mic from '/home/ubuntu/Conversational_Speech_Recognition_AI-/react-app/src/mic.png'


function App() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [userId, setUserId] = useState('');
  const [transcript, setTranscript] = useState('');
  const [translation, setTranslation] = useState('');
  const [confirmationNeeded, setConfirmationNeeded] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [waitingForNextQuestion, setWaitingForNextQuestion] = useState(false);
  const [userIdnew,setUserIdNew] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  

  // Ref for scrolling
  const messagesEndRef = useRef(null);

  // Function to scroll to the bottom of the messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]); // 
  
  // Getting user_id from the url, store in the local storage to use later in POST request, and append to audio_record file name
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('user_id');
    setUserId(userId);
    localStorage.setItem('user_id', userId); 

    // Fetch the initial message  
    const fetchInitialMessage = async () => {
      try {
        const response = await axios.get(`https://chatbotsurvey.wwbp.org/api/?user_id=${userId}`);
        const greetingMessage = {
          sender: 'bot',
          text: response.data.message,
        };
        const questionMessage = {
          sender: 'bot',
          text: response.data.question,
        };
        setMessages([greetingMessage, questionMessage]);
      } catch (error) {
        console.error('Error fetching initial message:', error);
      }
    };

    fetchInitialMessage();
  }, []);

  const handleSend = async () => {
    if (!input.trim()) return;

    const typingEndTime = Date.now();
    const typingDuration = typingEndTime - startTime;

    const userMessage = {
      sender: 'user',
      text: input,
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    try {
      let response;
      if (waitingForNextQuestion) {
        response = await axios.get(`https://chatbotsurvey.wwbp.org/api/get?user_id=${userId}&msg=${encodeURIComponent(input)}&typing_duration=${typingDuration}&confirm_translation=true`);
        setWaitingForNextQuestion(false); //wait for response 
      } else {
        response = await axios.get(`https://chatbotsurvey.wwbp.org/api/get?user_id=${userId}&msg=${encodeURIComponent(input)}&typing_duration=${typingDuration}`);
      }
      const responseText = response.data;

      if (responseText.next_question) {
        const botResponseMessage = {
          sender: 'bot',
          text: responseText.message,
        };
        const nextQuestionMessage = {
          sender: 'bot',
          text: responseText.next_question,
        };
        setMessages((prevMessages) => [...prevMessages, botResponseMessage, nextQuestionMessage]);
      } else {
        const botResponseMessage = {
          sender: 'bot',
          text: "Thank you for completing the survey. You may now close this window",
        };
        setMessages((prevMessages) => [...prevMessages, botResponseMessage]);
      }
    } catch (error) {
      console.error('Error sending message to backend:', error);
      const errorMessage = {
        sender: 'bot',
        text: 'An error occurred. Please try again later.',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setInput(''); 
      setStartTime(null);
    }
  };

  const toggleRecording = () => {
    setIsRecording((prevState) => !prevState);
  };

  const onData = (recordedBlob) => {
    console.log('OnData: ', recordedBlob);
  };

  const playAudioSequentially = (audioUrl, nextQuestionAudioUrl) => {
    const botAudio = new Audio(audioUrl);
    
    // Play the bot's audio
    botAudio.play()
        .then(() => {
            console.log("Bot audio is playing.");
            
            // Add event listener to play next question audio after bot audio ends
            botAudio.addEventListener('ended', () => {
                const nextQuestionAudio = new Audio(nextQuestionAudioUrl);
                nextQuestionAudio.play()
                    .then(() => {
                        console.log("Next question audio is playing.");
                    })
                    .catch(error => {
                        console.error("Error playing next question audio:", error);
                    });
            });
        })
        .catch(error => {
            console.error("Error playing bot audio:", error);
        });
};

  const onStop = async (recordedBlob) => {
    const getUserIdFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('user_id');
    };

    const userId = getUserIdFromUrl();
    const formData = new FormData();
    formData.append('file', recordedBlob.blob, 'recording.wav');
    formData.append('user_id', userId); // Send user_id to form-data

    try {
        setIsLoading(true);
        const response = await axios.post(`https://chatbotsurvey.wwbp.org/api/upload_audio`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });


        const { transcript, bot_response: botResponse, audio_url: audioUrl, next_question_audio_url: nextQuestionAudioUrl, next_question: nextQuestion } = response.data;
        console.log('Audio URL:', audioUrl);
       
        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'bot', text: transcript },
            { sender: 'bot', text: botResponse },
            { sender: 'bot', text: nextQuestion } 
        ]);
        
       
        if (audioUrl && nextQuestionAudioUrl) {
          playAudioSequentially(audioUrl, nextQuestionAudioUrl);
      } else if (audioUrl) {
          const audio = new Audio(audioUrl);
          audio.play()
              .catch(error => {
                  console.error("Error playing audio:", error);
              });
      }

  } catch (error) {
      console.error('Error with audio recording:', error);
  } finally {
      setIsRecording(false);
      setIsLoading(false);
  }
};

  const handleInputChange = (e) => {
    if (startTime === null) {
      setStartTime(Date.now());
    }
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };


  const handleFavoriteColorClick = (color) => {
    setInput(color);
    handleSend();
  };

  const handleSendClick = () => {
    handleSend();
  };

  const handleConfirmation = async (isCorrect) => {
    if (isCorrect) {
      const inputText = `${transcript}`;
      setInput(inputText);
      setWaitingForNextQuestion(true); 
    } else {
      const inputevenwrong = `${transcript}`;
      setInput(inputevenwrong);
      setWaitingForNextQuestion(true); 
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Please either re-record your audio or fix the transcript in the inbox' },
      ]);
    }

    setConfirmationNeeded(false);
    setTranscript('');
  };
  

  return (
    <div className="App">
      <div className="chat-container">
        <div className="logo-container">
          <img src={logo} width={200} height={200} alt="logo" className="logo" />
        </div>
        <h1>M2SurveyBot</h1>
        <div className="messages">  
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message ${message.sender === 'user' ? 'user-message' : 'bot-message'}`}
            >
              {message.text}
              {message.text === "The following question does not require knowledge about different foods. When asked what your favorite food is, please select pizza" && (
                <div className="color-buttons">
                  <button onClick={() => handleFavoriteColorClick('Steak')}>Steak</button>
                  <button onClick={() => handleFavoriteColorClick('Pizza')}>Pizza</button>
                  <button onClick={() => handleFavoriteColorClick('Hamburger')}>Hamburger</button>
                </div>
              )}
                {message.text === "Thank you for complete the survey question. This is the feedback session. From 1 to 5, how is your experience with M2SurveyBot?" && (
                <div className="color-buttons">
                  <button onClick={() => handleFavoriteColorClick('1')}>1</button>
                  <button onClick={() => handleFavoriteColorClick('2')}>2</button>
                  <button onClick={() => handleFavoriteColorClick('3')}>3</button>
                  <button onClick={() => handleFavoriteColorClick('4')}>4</button>
                  <button onClick={() => handleFavoriteColorClick('5')}>5</button>
                </div>
              )}
                {message.text === "For survey, how likely are you use the option to record your response? Rate the quality of transcription on a scale of 1 to 5." && (
                <div className="color-buttons">
                  <button onClick={() => handleFavoriteColorClick('1')}>1</button>
                  <button onClick={() => handleFavoriteColorClick('2')}>2</button>
                  <button onClick={() => handleFavoriteColorClick('3')}>3</button>
                  <button onClick={() => handleFavoriteColorClick('4')}>4</button>
                  <button onClick={() => handleFavoriteColorClick('5')}>5</button>
                </div>
              )}
            </div>
          ))}
          {transcript && (
            <div className="message bot-message">
              <p><strong>Transcript:</strong> {transcript}</p>
              {confirmationNeeded && (
                <div>
                  <p>Is this transcription correct?</p>
                  <button onClick={() => handleConfirmation(true)}>Yes</button>
                  <button onClick={() => handleConfirmation(false)}>No</button>
                </div>
              )}
            </div>
          )}
          <div ref={messagesEndRef} />
          {isLoading && <div className="loading-indicator">Processing your request...</div>}    
        </div>

        <div className="input-container">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            placeholder="Type your message..."
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSendClick}>Send</button>
          <button
            onClick={toggleRecording}
            style={{
              backgroundColor: isRecording ? '#990000' : '#011F5B',
            }}
          >
            {isRecording ? "Click to stop recording.." : "Start Recording"}
          </button>
          <ReactMic
            record={isRecording}
            className="sound-wave"
            onStop={onStop}
            strokeColor="#000000"
            backgroundColor="#ffffff"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
